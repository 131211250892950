import React, { useRef } from "react"
import underline from "../../images/proposito/underlineCendero.png"
import senderoEnd from "../../images/proposito/senderoEnd.png"
import arrowTwo from "../../images/proposito/arrowTwoCausa.png"
import llaoTwo from "../../images/proposito/LlaoLlao2.png"
import llaoThree from "../../images/proposito/Llaothree.png"
import circle from "../../images/proposito/greenPoint.png"
import arrowLlao from "../../images/proposito/arrowLlao.png"
import lataEnSendero from "../../images/proposito/lataEnSendero.png"
import lataSenderoTwo from "../../images/proposito/lataSendero2.png"
import imageMicro from "../../images/proposito/causaMicro.png"
import Parallax from "./Parallax"
import lataEnd from "../../images/proposito/lataEnd.png"
import separatorMobile from "../../images/proposito/greenSeparatorMobile.png"
import { Link } from "gatsby"
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect"
import FadeIn from './utilities/FadeIn'

// Parallax Effect created with hooks

const CervezaConCausa = () => {
  const ref1 = useRef(null)
  const ref2 = useRef(null)
  const ref3 = useRef(null)
  const ref4 = useRef(null)
  const ref5 = useRef(null);
  return (
    <div id="cervezaCausaCont">
      <div className="cervezaCausaTop">
        <div className="causaTopTitleContainer">
          <div className="pointerYear">
            <div className="causaTopYear">{"2020"}</div>
          </div>
          <div className="causaTitleContainer">
            <div className="causaTopTitle"> {"Sendero Sur"}</div>
            <div>
              <img src={underline} alt="underline" />
            </div>
          </div>
        </div>

        <div className="causaStory odd">
          <div className="causaStoryDiv left">
            <div>
              <p className="causaTextDesktop">
                Redoblamos nuestro compromiso con la nueva edición limitada
                Sendero Sur. Esta vez decidimos ampliar la iniciativa envasando
                esta variedad en lata y así destinar{" "}
                <mark className="fontBlack">
                  parte de los ingresos no solo al Parque Municipal Llao Llao
                  sino también al Parque Nacional Nahuel Huapi.
                </mark>
                <br />
                <br />
                Esta edición limitada nos invita a recorrer los caminos que nos
                conectan con nuestro origen.
              </p>

              <p className="causaTextMobile">
                Redoblamos nuestro compromiso con la nueva edición limitada
                Sendero Sur. Esta vez decidimos ampliar la iniciativa envasando
                esta variedad en lata y así destinar parte de los ingresos{" "}
                <mark className="fontBlack">
                  no solo al Parque Municipal Llao Llao sino también al Parque
                  Nacional Nahuel Huapi.
                </mark>
              </p>
            </div>
          </div>
          <div className="causaStoryDiv right">
            <div className="causaImagenContainer" ref={ref1}>
              {/* <Parallax src={lataSenderoTwo} position={'right'} end={true} refer={ref1}/> */}
              <FadeIn img={lataSenderoTwo} position={'right'} end={true} />
            </div>
          </div>
        </div>

        <div className="arrowAcciones">
          <img src={arrowTwo} alt="arrow" />
        </div>

        <div className="causaStory even">
          <div className="causaStoryDiv left">
            <div className="causaImagenContainer" ref={ref2}>
              {/* <Parallax src={lataEnSendero} position={'left'} end={true} refer={ref2}/> */}
              <FadeIn img={lataEnSendero} position={'left'} end={true} />
            </div>
          </div>
          <div className="causaStoryDiv right">
            <div>
              <p className="causaTextDesktop">
                La intención de esta cerveza es{" "}
                <mark className="fontBlack">
                  colaborar con el equipo de Parques Nacionales y el Parque
                  Municipal en la preservación y mantenimiento de senderos de
                  las áreas protegidas correspondientes.
                </mark>{" "}
                De esta manera abrimos un nuevo vínculo de colaboración para
                facilitar el trabajo de los guardaparques locales y para
                conservar los caminos que nos permiten explorar responsablemente
                los paisajes de la región.
              </p>

              <p className="causaTextMobile">
                Esta edición limitada nos invita a recorrer los caminos que nos
                conectan con nuestro origen. La intención de esta cerveza es
                <mark className="fontBlack">
                  colaborar con el equipo de Parques Nacionales y el Parque
                  Municipal en la preservación y mantenimiento de senderos de
                  las áreas protegidas correspondientes.
                </mark>{" "}
                De esta manera abrimos un nuevo vínculo de colaboración para
                facilitar el trabajo de los guardaparques locales y para
                conservar los caminos que nos permiten explorar responsablemente
                los paisajes de la región.
              </p>
            </div>
            <div className="btnConseguila">
              <a
                href={
                  "https://www.craftsociety.com.ar/pages/patagonia-senderosur?utm_source=patagonia&utm_medium=store&utm_campaign=patagonia-sendero-sur&utm_content=web"
                }
                target="_blank" rel="noreferrer"
              >
                Conseguila acá
              </a>
            </div>
          </div>
        </div>

        <div className="causaStory odd">
          <div className="causaStoryDiv left">
            <div>
              <p className="causaTextDesktop">
                <mark className="fontBlack">Nahuel Huapi</mark> es el primer
                Parque Nacional del país. Es un{" "}
                <mark className="fontBlack">
                  refugio de naturaleza y cultura
                </mark>{" "}
                que resguarda una muestra representativa de los ecosistemas
                andinonorpatagónicos: bosques, estepa y alta montaña, así como
                también las cuencas hídricas y el patrimonio cultural. Su
                territorio lo comprenden 717.261 hectáreas que hacen de esta
                área protegida un lugar con características únicas y
                difícilmente repetibles.
              </p>
              <p className="causaTextMobile">
                <mark className="fontBlack">Nahuel Huapi</mark> es el primer
                Parque Nacional del país. Es un{" "}
                <mark className="fontBlack">
                  refugio de naturaleza y cultura
                </mark>{" "}
                que resguarda una muestra representativa de los ecosistemas
                andinonorpatagónicos: bosques, estepa y alta montaña, así como
                también las cuencas hídricas y el patrimonio cultural. Su
                territorio lo comprenden 717.261 hectáreas que hacen de esta
                área protegida un lugar con características únicas y
                difícilmente repetibles.
              </p>
            </div>
          </div>
          <div className="causaStoryDiv right">
            <div className="causaImagenContainer marginPicture" ref={ref3}>
            {/* <Parallax src={imageMicro} position={'right'} end={true} refer={ref3}/> */}
            <FadeIn img={imageMicro} position={'right'} end={true}/>
            </div>
          </div>
        </div>
        <img className="greenSeparatorMobile" src={separatorMobile} />
        <div className="senderoEnd">
          <img src={senderoEnd} alt="senderoEnd" />
        </div>
      </div>
      {/* Llao Llao */}

      <div className="causaBottom">
        <div className="causaTopTitleContainer">
          <div className="pointerYear">
            <div className="causaTopYear">2019</div>
          </div>
          <div className="causaTitleContainer">
            <div className="causaTopTitle">Llao Llao</div>
            <div>
              <img src={underline} alt="underline" />
            </div>
          </div>
        </div>

        <div className="causaStory odd">
          <div className="causaStoryDiv left">
            <div>
              <p className="causaTextDesktop">
                En el marco del aniversario de nuestra Microcervecería,
                decidimos lanzar Llao Lao, una variedad cuyo objetivo fue{' '}
                <mark className="fontBlack">
                  colaborar con el cuidado del Parque Municipal Llao Llao
                </mark>
                , vecino de nuestra Micro.
                <br />
                <br />
                Los ingresos provenientes de la venta de esta cerveza se
                destinaron a recursos materiales para el mantenimiento del
                Parque. Además colaboramos con el equipo de guardaparques para
                puedan mantener en condiciones este{" "}
                <mark className="fontBlack">lugar único</mark> y todos sigamos
                disfrutando del paisaje que a nosotros nos acompaña día a día.
              </p>

              <p className="causaTextMobile">
                En el marco del aniversario de nuestra Microcervecería,
                decidimos lanzar Llao Lao, una variedad cuyo objetivo fue{" "}
                <mark className="fontBlack">
                  colaborar con el cuidado del Parque Municipal Llao Llao
                </mark>
                , vecino de nuestra Micro.
                <br />
                <br />
                Los ingresos provenientes de la venta de esta cerveza se
                destinaron a recursos materiales para el mantenimiento del
                Parque. Además colaboramos con el equipo de guardaparques para
                puedan mantener en condiciones este{" "}
                <mark className="fontBlack">lugar único</mark> y todos sigamos
                disfrutando del paisaje que a nosotros nos acompaña día a día.
              </p>
            </div>
          </div>
          <div className="causaStoryDiv right">
            <div className="causaImagenContainer marginPicture" ref={ref4}>
            {/* <Parallax src={llaoTwo} position={'right'} end={false} refer={ref4}/> */}
            <FadeIn img={llaoTwo} position={'right'} end={false}/>
            </div>
          </div>
        </div>
        <div className="causaStorySeparator">
          <div>
            <img  alt="" src={circle} />
          </div>
          <div>
            <img  alt="" src={arrowLlao} />
          </div>
          <div />
        </div>
        <div className="causaStory even noMargin">
          <div className="causaStoryDiv left">
            <div className="causaImagenContainer marginPicture" ref={ref5}>
            {/* <Parallax src={llaoThree} position={'left'} end={false} refer={ref5}/> */}
            <FadeIn img={llaoThree} position={'right'} end={false}/>
            </div>
          </div>
          <div className="causaStoryDiv right">
            <div>
              <p className="causaTextDesktop">
                El Parque Municipal Llao Llao es nuestro vecino en Circuito
                Chico y{" "}
                <mark className="fontBlack">comprende 1226 hectáreas</mark> en
                las que conviven árboles autóctonos como coihue, ciprés, arrayán
                y una gran diversidad de animales como el huillín, monito de
                monte, cóndores y más. El mismo recibe a miles de visitantes
                durante cada temporada y es un{" "}
                <mark className="fontBlack">
                  punto turístico imperdible para visitar
                </mark>
                . Es nuestro vecino y los  paisajes desde el Cerrito Llao Llao
                son una fuente de inspiración constante para nosotros.
              </p>

              <p className="causaTextMobile">
                El Parque Municipal Llao Llao es nuestro vecino en Circuito
                Chico y{" "}
                <mark className="fontBlack">comprende 1226 hectáreas</mark> en
                las que conviven árboles autóctonos como coihue, ciprés, arrayán
                y una gran diversidad de animales como el huillín, monito de
                monte, cóndores y más. El mismo recibe a miles de visitantes
                durante cada temporada y es un{" "}
                <mark className="fontBlack">
                  punto turístico imperdible para visitar
                </mark>
                . Es nuestro vecino y los  paisajes desde el Cerrito Llao Llao
                son una fuente de inspiración constante para nosotros.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CervezaConCausa
