import React from "react"
import underline from "../../images/proposito/underlineAcciones.png"
import circle from "../../images/proposito/circle.png"
import leftFixed from "../../images/proposito/leftFixedPropo.png"
import highlight from "../../images/proposito/highlight.png"
import rightArrow from "../../images/proposito/RightArrow.png"
import leftArrow from "../../images/proposito/LeftArrow.png"
import separatorHeader from "../../images/proposito/separatorHeaderMobile.png"

const NuestrasAccionesHeader = () => {
  return (
    <div id="nuestrasAccHeader">
      <img  alt="" className="fixedPropo" src={leftFixed} />
      <div>
        <div className="accionesHeaderTop">
          <div className="nuestrasAccionesCont">
            <div className="nuestrasAccionesTitleContainer">
              <h1 className="nuestrasAcciones">
                Nuestras
                <br />
                acciones
              </h1>
              <img  alt="" src={underline} />
            </div>
          </div>
          <p className="nuestrasAccionesText">
            Si hay algo que nos caracteriza es crear cervezas, y qué mejor que
            lanzar una <mark className="fontBlack">exclusiva para
            <span className="highlighted"> colaborar con la preservación </span>
            y el cuidado de áreas protegidas</mark> de nuestro origen.
          </p>
        </div>
        <div className="circleContainer">
          <img  alt="" className="nuestrasAccionesCircle" src={circle} />
        </div>
      </div>
      <div className="separatorHeader">
        <img  alt="" src={separatorHeader} />
      </div>
      {/* <div className="accionesHeaderBottom">
        <img  alt="" className="arrow" src={leftArrow} />
        <div className="accionesHeaderButton">{"Cerveza con causa"}</div>
        <div className="accionesHeaderButton">{"Reforestación"}</div>
        <div className="accionesHeaderButton">{"Huella de carbono"}</div>
        <div className="accionesHeaderButton">{"Reforestación"}</div>
        <img  alt="" className="arrow" src={rightArrow} />
      </div> */}
    </div>
  )
}

export default NuestrasAccionesHeader
