import React from "react"
import classNames from "classnames"
import HeaderEnd from "../../images/proposito/HeaderEnd.png"
import background from "../../images/proposito/HeaderImage.png"

export const SECTION_PROPOSITO = "proposito"
export const SECTION_ACCIONES = "acciones"

const Header = ({ handler, section }) => {
  const accionesBtnClasses = classNames({
    headerButtonText: true,
    selected: section === SECTION_ACCIONES,
  })
  const propositoBtnClasses = classNames({
    headerButtonText: true,
    selected: section === SECTION_PROPOSITO,
  })

  return (
    <div id="headerPropContainer">
      <div className={"nuestroProposito"}>
          <h1>{"Nuestro propósito"}</h1>
      </div>
      <div className="headerButtonsContainer">
        <div
          className="headerButton"
          onClick={() => handler(SECTION_PROPOSITO)}
        >
          <h1 className={propositoBtnClasses}>{"Propósito"}</h1>
        </div>

        <div className="headerButton" onClick={() => handler(SECTION_ACCIONES)}>
          <h1 className={accionesBtnClasses}>{"Acciones"}</h1>
        </div>
      </div>
      <div className="HeaderEndCont">
        <img  alt="" src={HeaderEnd} className="imgHeaderEnd" />
      </div>
    </div>
  )
}

export default Header
