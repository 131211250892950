import React from 'react'
import NuestrasAccionesHeader from './NuestrasAccionesHeader'
import CervezaConCausa from './CervezaConCausa'

const NuestrasAcciones = () => {
    return(
        <>
        <NuestrasAccionesHeader/>
        <CervezaConCausa/>
        </>
    )
}

export default NuestrasAcciones