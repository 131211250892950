import React, {useState, useEffect} from "react"
import underline from "../../images/proposito/underline.png"
import underline2 from "../../images/proposito/underline2.png"
import leftFixed from "../../images/proposito/leftFixedPropo.png"
import propositoImg1 from "../../images/proposito/NuestroProposito1.png"
import propositoImg2 from "../../images/proposito/NuestroProposito2.png"
import propositoImg3 from "../../images/proposito/NuestroProposito3.png"
import carrouselImage1 from "../../images/proposito/carrousel1.png"
import carrouselImage2 from "../../images/proposito/carrousel2.png"
import carrouselImage3 from "../../images/proposito/carrousel3.png"
import arrowToLeft from "../../images/proposito/arrowToLeft.png"
import arrowToRight from "../../images/proposito/arrowToRight.png"
import arrowMobile from "../../images/proposito/arrowPropositoMobile.png"
import greenPoint from "../../images/proposito/greenPoint.png"
import propositoImg4 from "../../images/proposito/causaMicro.png"
import carrouselImage1Mobile from "../../images/proposito/NuestroProposito1Mobile.png"
import Parallax from "./Parallax"
import FadeIn from './utilities/FadeIn'

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"

const NuestroProposito = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  const carrouselImages = [
    {
      foto: carrouselImage1Mobile,
    },
    {
      foto: carrouselImage2,
    },
    {
      foto: carrouselImage3,
    },
  ]

  return (
    <div id="nuestroPropContainer">
      <img  alt="" className="fixedPropo" src={leftFixed} />

      <div className="causaStory odd">
        <div className="causaStoryDiv left propositoImage">
          <div
            className="causaImagenContainer"

          >
            {/* <Parallax src={propositoImg1} position={'left'} end={false} /> */}
            <FadeIn img={propositoImg1} position={'left'} end={false} />
          </div>
        </div>
        <div className="causaStoryDiv right propositoText">
          <div className="propositoTitulo">
            <h1>
              Nuestra
              <br />
              inspiración
            </h1>
            <div>
              <img  alt="" src={underline} />
            </div>
            <img  alt="" className="arrowPropoMobile" src={arrowMobile} />
          </div>
          <div>
            <p className="causaTextDesktop">
              Iniciamos  un camino para  colaborar  con  la <mark className="fontBlack">preservación de
              <span className="highlighted2"> áreas protegidas </span>y parques
              de la Patagonia.</mark> Estamos empezando por Bariloche, nuestra casa.
              <br />
              <br />
              Para nosotros la Patagonia es un lugar único en el mundo no solo
              por su geografía, sino también por su historia y su cultura. Por
              eso, desde Cerveza Patagonia <mark className="fontBlack">decidimos transformar nuestro
              propósito en una motivación, la de preservar su esencia:</mark> que se
              conserve eso tan único que a nosotros nos atrajo a este lugar.
            </p>

            <p className="causaTextMobile">
              Iniciamos  un camino para  colaborar  con  la <mark className="fontBlack">preservación de
              áreas protegidas y parques de la Patagonia.</mark>
              <br />
              <br />
              Estamos empezando por Bariloche, nuestra casa. Para nosotros a{" "}
              <span className="highlighted2">
              <mark className="fontBlack">Patagonia es un lugar único</mark>
              </span>{" "}
              <mark className="fontBlack">en el mundo</mark> no solo por su geografía, sino también por su historia
              y su cultura. Por eso, desde Cerveza Patagonia decidimos
              transformar nuestro propósito en una motivación, la de preservar
              su esencia: <mark className="fontBlack">que se conserve eso tan único que a nosotros nos
              atrajo a este lugar.</mark>
            </p>
          </div>
        </div>
        <img  alt="" className="arrowProp" src={arrowToLeft} />
      </div>

      <img  alt="" className="greenPoint" src={greenPoint} />

      <div className="causaStory odd noMargin">
        <div className="causaStoryDiv left propositoText">
          <div>
            <p className="causaTextDesktop">
              La Patagonia nos inspira, nos permite explorar y conocer, es
              nuestro origen, nuestro hogar. Nuestra microcervecería se ubica en
              el KM 24.7 de Circuito Chico, Bariloche, y es el lugar donde
              nuestro equipo cervecero crea nuevas recetas inspiradas en la
              naturaleza que los rodea. <mark className="fontBlack">Es en Bariloche donde más fuerte late el
              corazón de Cerveza Patagonia</mark> y donde nuestro espíritu explorador
              alcanza su máxima expresión.
            </p>

            <p className="causaTextMobile">
              La Patagonia nos inspira, nos permite explorar y conocer, es
              nuestro origen, nuestro hogar. Nuestra microcervecería se ubica en
              el KM 24.7 de Circuito Chico, Bariloche, y es el lugar donde
              nuestro equipo cervecero crea nuevas recetas inspiradas en la
              naturaleza que los rodea. Es en Bariloche donde más fuerte late el
              corazón de Cerveza Patagonia y donde nuestro espíritu explorador
              alcanza su máxima expresión. <mark className="fontBlack">Nuestro sueño es brindar la mejor experiencia cervecera del mundo
              desde la Patagonia, y es por eso que estamos determinados en
              formar parte de su cuidado.</mark>
              
            </p>
          </div>
        </div>
        <div className="causaStoryDiv right propositoImage">
          <div
            className="causaImagenContainer"
          >
            {/* <Parallax src={propositoImg2} position={'right'} end={false}/> */}
            <FadeIn img={propositoImg2} position={'right'} end={false} />

          </div>
        </div>
        <img  alt="" src={arrowToRight} className="arrowProp" />
      </div>

      <div className="causaStory odd noMargin">
        <div className="causaStoryDiv left propositoImage">
          <div
            className="causaImagenContainer"

          >
            {/* <Parallax src={propositoImg4} position={'left'} end={false}/> */}
            <FadeIn img={propositoImg4} position={'left'} end={false} />

          </div>
        </div>
        <div className="causaStoryDiv right propositoText underlineImage">
          <div>
            <p className="causaTextDesktop">
              Nuestro sueño es brindar la mejor experiencia cervecera del mundo
              desde la Patagonia, y es por eso que <mark className="fontBlack">estamos determinados en
              formar parte de su cuidado.</mark>
            </p>
          </div>
          <div>
            <img  alt="" src={underline2} />
          </div>
        </div>
      </div>

      <div className="carrouselProposito">
        <Slider {...settings}>
          {carrouselImages.map(image => {
            return (
              <div className="carrouselDiv">
                {<img  alt="" src={image.foto} className="carrouselImage" />}
              </div>
            )
          })}
        </Slider>
      </div>
    </div>
  )
}

export default NuestroProposito
