import React from "react"
import Header from "./Header"
import Bottom from "./Bottom"
import NuestroProposito from "./NuestroProposito"
import NuestrasAcciones from "./NuestrasAcciones"
import {SECTION_PROPOSITO} from './Header'


const PropositoComponent = ({section, handler, refProp}) => {
  
  return (
    <div id="propositoContainer" ref={refProp}>
      <Header 
      handler={handler}
      section={section}/>
      {section === SECTION_PROPOSITO ?
          <NuestroProposito />
          :
          <NuestrasAcciones />}
      <Bottom 
      handler={handler}
      section={section}
      />
    </div>
  )
}

export default PropositoComponent

