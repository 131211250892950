import React from "react"
import Header from "../components/header"
import Footer from "../components/Footer"
import Menu from "../components/Menu"
import MenuMobile from "../components/MenuMobile"
import SEO from "../components/seo"
import Cookies from "js-cookie"
import PropositoComponent from "../components/proposito/PropositoComponent"
import BirthConsent from "../components/BirthConsent"

import {
  SECTION_PROPOSITO,
  SECTION_ACCIONES,
} from "../components/proposito/Header"

const hasConfirmed = () => {
  return (
    (Cookies.get("patagoniaBirthConsent") !== undefined &&
      Cookies.get("patagoniaBirthConsent")) ||
    (localStorage.getItem("patagoniaBirthConsent") !== undefined &&
      localStorage.getItem("patagoniaBirthConsent"))
  )
}

export default class Proposito extends React.Component {
  constructor(props) {
    super(props)
    this.ref = React.createRef()
    this.state = {
      isScrolling: false,
      menuOpen: false,
      menuClass: "slideOut",
      isDark: false,
      width: 1000,
      birthConsent: true,
      section: SECTION_PROPOSITO,
    }
  }

  componentDidMount() {
    window.addEventListener("scroll", this.onScroll)
    this.setState({
      width: window.innerWidth,
    })

    if (hasConfirmed()) {
      this.handleConsent()
    } else {
      this.setState({
        birthConsent: false,
      })
    }
  }

  handleConsent = () => {
    this.setState({
      birthConsent: true,
    })
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll)
  }

  handleChangeSection = section => {
    this.setState({ section })
    if (this.ref && this.ref.current) {
      this.ref.current.scrollIntoView({ behavior: "smooth", block: "start" })
    }
  }

  onMenuToggle = () => {
    this.setState({
      menuOpen: !this.state.menuOpen,
      menuClass: this.state.menuOpen ? "slideOut" : "slideIn",
      isDark: !this.state.menuOpen,
    })
  }

  onScroll = () => {
    this.setState({ isScrolling: true })

    clearTimeout(this.timeout)

    this.timeout = setTimeout(() => {
      this.setState({ isScrolling: false })
    }, 200)
  }

  render() {
    return (
      <div id="propositoContainer">
        <SEO title="Nuestro Propósito" />
        <Header
          isScrolling={this.state.isScrolling}
          onMenuToggle={this.onMenuToggle}
          isDark={this.state.menuOpen}
          menuOpen={this.state.menuOpen}
          width={this.state.width}
        >
          {/* <div
            click={() => this.handleChangeSection(SECTION_PROPOSITO)}
            text={"Nuestro propósito"}
          />
          <div
            click={() => this.handleChangeSection(SECTION_ACCIONES)}
            text={"Nuestras acciones"}
          /> */}
        </Header>
        {this.state.birthConsent && (
          <PropositoComponent
            refProp={this.ref}
            section={this.state.section}
            handler={this.handleChangeSection}
            section={this.state.section}
          />
        )}
        <Menu menuClass={this.state.menuClass} menuOpen={this.state.menuOpen} />
        {!this.state.birthConsent && (
          <BirthConsent handleConsent={this.handleConsent} />
        )}
        {this.state.width > 767 ? (
          <Menu
            menuClass={this.state.menuClass}
            menuOpen={this.state.menuOpen}
          />
        ) : (
          <MenuMobile
            onMenuToggle={this.onMenuToggle}
            menuClass={this.state.menuClass}
            menuOpen={this.state.menuOpen}
          />
        )}
        <Footer />
      </div>
    )
  }
}
